import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, afterNextRender, inject } from '@angular/core';
// import { User, ChangePassword } from '../interface/user.type';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions: any = {
  observe: 'response',
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();
  public readonly IS_LOGGED_IN_KEY = 'isLoggedIn';

  private avatarSubject = new BehaviorSubject<string | null>(null)
  avatar$ = this.avatarSubject.asObservable();

  setIsLoggedIn(value: boolean): void {
    this.isLoggedInSubject.next(value);
    // afterNextRender(() => {
    if (value) {
      localStorage.setItem(this.IS_LOGGED_IN_KEY, 'true');
    } else {
      localStorage.removeItem(this.IS_LOGGED_IN_KEY);
    }
    // })

  }
  // 退出登陆
  clearLoginStatus(): void {
    this.setIsLoggedIn(false);
  }

  setAvatar(avatar: string) {
    this.avatarSubject.next(avatar);
  }

  constructor(private http: HttpClient) {
    // 在服务初始化时,从 localStorage 中读取登录状态
    afterNextRender(() => {
      this.isLoggedInSubject.next(!!localStorage.getItem(this.IS_LOGGED_IN_KEY));
    })
  }

  apiUrl = environment.baseUrl

  getUsers(page: number, pageSize: number) {
    return this.http.get(`${this.apiUrl}/users?_page=${page}&_limit=${pageSize}`, { observe: 'response' })
  }

  getUser(userID: string) {
    return this.http.get(`${this.apiUrl}/users/${userID}`)
  }

  register = (user: any) => {
    return this.http.post(`${this.apiUrl}/users/register`, user, { observe: 'response' })
  }

  login = (user: any) => {
    // console.log(user);
    return this.http.post(`${this.apiUrl}/users/login`, user, { observe: 'response' })
  }

  captcha = () => {
    return this.http.get<any>(`${this.apiUrl}/users/captcha`);
  }

  token = () => {
    return this.http.get(`${this.apiUrl}/users/token`, { observe: 'response' })
  }

  logout = () => {
    return this.http.get(`${this.apiUrl}/users/logout`)
  }

  updatePassword(changePassword: any) {
    return this.http.patch(`${this.apiUrl}/users/password`, changePassword)
  }

  // 还可以考虑分页
  search = (searchQuery: string) => {
    return this.http.get(`${this.apiUrl}/users/search?query=${searchQuery}`, { observe: 'response' })
  }

  private handleError(error: any): Observable<never> {
    console.error('发生错误：', error);
    throw error;
  }

  ///////////////////////////// new api
  updateUser(user: any) {
    return this.http.put(`${this.apiUrl}/users`, user)
  }

  toggleUserDisabled(id: number) {
    return this.http.patch(`${this.apiUrl}/users/${id}/toggle`, '', { observe: 'response' })
  }


  updateAvatar(id: number, avatar: any) {
    return this.http.patch(`${this.apiUrl}/users/${id}/avatar`, avatar).pipe(
      tap((response: any) => {
        console.log(response);
        
        if (response && response.avatar) {
          this.avatarSubject.next(response.avatar);
        }
      })
    );
  }


}
